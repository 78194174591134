import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import swal from 'sweetalert';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    data: Date = new Date();
    focus;
    focus1;
    showlogin: boolean = false;
    // loginForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private userScv: UserService,
        private authScv: AuthenticationService
    ) { }
    registerForm: FormGroup;
    loginForm: FormGroup;

    ngOnInit() {

        this.authScv.logout();

        this.registerForm = this.fb.group({
            first_name: '',
            last_name: '',
            email: '',
            //access: this.fb.array([this.createItem()]),

        })
        this.loginForm = this.fb.group({
            email: '',
        })

    }
    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    registersection() {
        this.showlogin = !this.showlogin;
       
    }

    registeruser() {
        this.authScv.registerUser(this.registerForm.value)
    }
    login() {
        this.authScv.login(this.loginForm.value)
    }

}



