import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  registerUser(RegisterData) {
    return this.http.post<any>(`http://api.beingabundance.com/api/register`, RegisterData);
  }
}
