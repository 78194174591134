import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpBackend } from '@angular/common/http';
import { SnackbarService } from '../services/snackbar.service';
import 'rxjs/add/operator/do';

@Injectable()
export class I2 implements HttpInterceptor {

    constructor(public snackbarService: SnackbarService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).do(
            (event: HttpEvent<any>) => {
                if (req.method == "POST") {
                    if (event instanceof HttpResponse)
                        this.snackbarService.showsuccess(event.body.message);
                }

            },
            (err: any) => {
                setTimeout(()=>{ 
                    this.snackbarService.show(err);
               },1000);
            }
        );
    }
}
