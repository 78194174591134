import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './components/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { CardGameComponent } from './components/card-game/card-game.component';
import { OpenResourcesComponent } from './open-resources/open-resources.component';
import { CardgameContentComponent } from './components/cardgame-content/cardgame-content.component';

const routes: Routes =[
    { path: '', redirectTo: 'cardgame', pathMatch: 'full' },
    { path: 'home',             component: CardGameComponent },
    // { path: 'resources',        component: ResourcesComponent },
    { path: 'cardgame',          component: CardGameComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'signup',           component: SignupComponent },
    // { path: 'landing',          component: CardGameComponent },
    // { path: 'brionyresource',  component: OpenResourcesComponent },
    { path: 'logout',           component: SignupComponent },
    {path: 'todayscard/:cardId', component:CardgameContentComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
    // RouterModule.forRoot(routes)

  ],
  exports: [
  ],
})
export class AppRoutingModule { }
