import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import swal from 'sweetalert';
import { CardserviceService } from 'app/services/cardservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-cardgame-content',
  templateUrl: './cardgame-content.component.html',
  styleUrls: ['./cardgame-content.component.css']
})
export class CardgameContentComponent implements OnInit {

  constructor(private httpClient: HttpClient, private cardSvc: CardserviceService, private route: ActivatedRoute, private datepipe: DatePipe) {
  }
  CurrentUserMail;
  profileData;
  todaydate;
  selectedCardContent
  selectedData
  CardDescription
  showDescription: boolean = false;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    var cardnumber = this.route.snapshot.params['cardId'];
    this.CurrentUserMail = localStorage.getItem('currentUser');
    var latest_date = new Date();
    this.todaydate = this.datepipe.transform(latest_date, 'dd-MM-yyyy');

    var arr = { email: this.CurrentUserMail };
    this.cardSvc.getprofile(arr).subscribe(data => {
      var profilearr = data.data;
      this.profileData = profilearr[0];
      var carddata = {
        product_id: cardnumber,
        user_id: this.profileData.id,
        email: this.CurrentUserMail,
        last_opened_date: this.todaydate
      };
      this.cardSvc.carddetails(carddata).subscribe(data => {
        if (data.status == true) {
          this.httpClient.get("assets/cardcontent.json").subscribe(data => {
            console.log(data);
            this.selectedData = data;
            var cardvalues = cardnumber % 10;
            this.selectedCardContent = this.selectedData[cardvalues];
            this.CardDescription = this.selectedCardContent.description
            console.log(this.CardDescription);
          })
        }
        if (data.status == false) {
          swal("Sorry!", "You have drawn this card already, we invite you for drawing a new card tomorrow");
        }

        //card success


      })


    })
    


  }

  showdesc() {
    this.showDescription = true;
  }


}
