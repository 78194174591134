import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service'
@Component({
  selector: 'app-loginmodal',
  templateUrl: './loginmodal.component.html',
  styleUrls: ['./loginmodal.component.css']
})
export class LoginmodalComponent implements OnInit {
  @Input() public product;
  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private loginsvc: AuthenticationService,

  ) { }

  LoginForm: FormGroup;
  ngOnInit(): void {

    this.LoginForm = this.fb.group({
      email: '',
    })
  }

  login() {
    this.loginsvc.login(this.LoginForm.value);
    // this.activeModal.close();
  }


}
