import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';


@Injectable()
export class CartService {
  // Local variable which stores 
  public cartItems = [];
  public products = new Subject();

  

  // getProducts(): Observable<any> {
  //   console.log('this.cartItems :', this.cartItems);
  //   return this.products.asObservable();
  // }

  // setProducts(prconsole.log("came in");oducts) {
  //   this.cartItems.push(...products);
  //   this.products.next(products);
  // }

  // Add single product to the cart
  addProductToCart(product) {
    console.log("came in");
    this.cartItems.push(product);
    console.log(this.cartItems);
    // this.products.next(this.cartItems);
  }

  // Remove single product from the cart
  // removeProductFromCart(productId) {
  //   this.cartItems.map((item, index) => {
  //     if (item.id === productId) {
  //       this.cartItems.splice(index, 1);
  //     }
  //   });

  //   // Update Observable value
  //   // this.products.next(this.cartItems);
  // }

  // Remove all the items added to the cart
  // emptryCart() {
  //   this.cartItems.length = 0;
  //   this.products.next(this.cartItems);
  // }

 

}